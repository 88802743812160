import { Injectable, OnDestroy } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { getLocalStorage } from '@logic-suite/shared/utils/getLocalStorage';
import { BehaviorSubject, ReplaySubject, Subscription, filter } from 'rxjs';
import { Employee, EmployeeCustomer } from './profile.model';

const key = 'customer';
@Injectable({
  providedIn: 'root',
})
export class EmployeeService implements OnDestroy {
  private emp$ = new BehaviorSubject<Employee>(undefined as unknown as Employee);
  private customer$ = new ReplaySubject<EmployeeCustomer>(1);
  private currentCustomer?: EmployeeCustomer;
  currentEmployee = toSignal(this.emp$);

  subscriptions: Subscription[] = [];

  constructor() {
    this.subscriptions.push(
      this.getEmployee().subscribe((employee: Employee) => {
        const customerStr = getLocalStorage().getItem(key);
        let customer: EmployeeCustomer | undefined =
          customerStr != null && typeof customerStr === 'string'
            ? (JSON.parse(customerStr) as EmployeeCustomer)
            : undefined;

        // Remove customer if set customer is one that user does not have
        if (customer != null && employee.customers?.findIndex(c => c.customerID === customer?.customerID) < 0)
          customer = undefined;

        // If no customer is set, pick the first
        if (!customer) customer = employee.customers[0];

        // Store for later
        this.setCustomer(customer);
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getCurrentCustomer() {
    return this.currentCustomer;
  }

  getCustomer$() {
    return this.customer$;
  }

  setCustomer(cust: EmployeeCustomer) {
    const custStr = JSON.stringify(cust);
    const oldStr = getLocalStorage().getItem(key);
    // Only set if new customer is not the same as old customer
    if (custStr != null && custStr != oldStr) {
      getLocalStorage().setItem(key, JSON.stringify(cust));
      this.currentCustomer = cust;
      this.customer$.next(cust);
    }
    if (custStr != null && this.currentCustomer == null) {
      // Set this initially
      this.currentCustomer = cust;
      this.customer$.next(cust);
    }
  }

  setEmployee(emp: Employee) {
    this.emp$.next(emp);
  }

  getEmployee() {
    return this.emp$.pipe(filter(emp => !!emp));
  }
}
